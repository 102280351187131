<template>
  <div>
    <div class="columns">
      <div class="column is-flex is-align-items-center">
        <p class="title is-size-5">Invoice Control</p>
      </div>
      <div v-if="assessments.length > 0 && isLoadingInvoice"
        class="section">
        <span class="is-loading-image has-text-grey has-text-centered" />
      </div>
      <div v-if="assessments.length > 0 && !isLoadingInvoice"
        class="column is-flex is-justify-content-flex-end">
        <button @click="raiseInvoice"
          class="button is-info"
          :disabled="!invoice.assetId || (gstInvoice ? !gstInvoice.assetId : false)">
          <span class="icon"><i class="mdi mdi-playlist-check mdi-18px" /></span>
          <span>Raise Invoice</span></button>
      </div>
    </div>
    <div class="is-divider mt-0 mb-4" />
    <div v-if="invoice && !isLoadingInvoice">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Invoice No.</label>
            <div class="field has-addons">
              <div class="control">
                <input class="input is-static"
                  :value="invoiceNoReference"
                  type="text"
                  placeholder="Invoice No."
                  readonly>
              </div>
              <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
                class="control">
                <button v-if="!selectedInvoice"
                  class="button is-warning is-outlined tooltip is-tooltip-warning"
                  data-tooltip="Change invoice no."
                  @click="showChangeInvoiceNoModal">
                  <span class="icon is-medium">
                    <i class="mdi mdi-pencil-outline mdi-24px" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div v-if="selectedInvoice"
            class="field">
            <label class="label">Invoice Date</label>
            <input class="input is-static"
              :value=" $filters.formatDateTimezone(invoice.invoiceDate, $userInfo.locale)"
              readonly>
          </div>
          <div v-else
            class="field">
            <label class="label">Invoice Date</label>
            <div class="control">
              <v-date-picker v-model="invoice.invoiceDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :min-date="firstDayOfMonth"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Invoice Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="is-divider mt-0 mb-4" />
      <div v-if="selectedInvoice"
        class="field">
        <label class="label">Payer</label>
        <input class="input is-static"
          :value="invoice.assetName"
          type="text"
          readonly>
      </div>
      <div v-else
        class="field">
        <div class="field"
          v-if="invoice.invoicePayingType === invoicePayerTypes.ThirdParty">
          <payer-selector v-model="innerValue"
            @payer-select="onThirdPartyPayerSelect" />
        </div>
        <div class="control">
          <label v-if="invoice.invoicePayingType !== invoicePayerTypes.ThirdParty"
            class="label">Payer</label>
          <div class="pretty p-default p-round p-smooth mr-6">
            <input name="rd-payerType1"
              :value="invoicePayerTypes.Insurer"
              v-model="invoice.invoicePayingType"
              @click="onInvoicePayerTypeClick(invoicePayerTypes.Insurer)"
              type="radio">
            <div class="state p-primary-o">
              <label>Insurer</label>
            </div>
          </div>
          <div class="pretty p-default p-round p-smooth mr-6">
            <input name="rd-payerType2"
              :value="invoicePayerTypes.Private"
              v-model="invoice.invoicePayingType"
              @click="onInvoicePayerTypeClick(invoicePayerTypes.Private)"
              :disabled="!customerAsset"
              type="radio">
            <div class="state p-primary-o">
              <label>Private</label>
            </div>
          </div>
          <div class="pretty p-default p-round p-smooth mr-6">
            <input name="rd-payerType2"
              :value="invoicePayerTypes.ThirdParty"
              v-model="invoice.invoicePayingType"
              @click="onInvoicePayerTypeClick(invoicePayerTypes.ThirdParty)"
              type="radio">
            <div class="state p-primary-o">
              <label>3rd Party</label>
            </div>
          </div>
        </div>
        <span v-if="!customerAsset"
          class="help is-danger">Private invoice disabled. Customer has been deleted</span>
      </div>
      <div class="is-divider mt-0 mb-4" />
      <div v-if="isMainInvoice">
        <div class="columns">
          <div class="column">
            <div class="field">
              <div class="is-flex is-justify-content-space-between">
                <label class="label">Excess</label>
                <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                  <label class="pretty-checkbox-label pr-1 is-size-7"
                    for="chk-invoiceExcessWtihGst">Excess with GST</label>
                  <div class="pretty p-icon p-round p-smooth m-0">
                    <input id="chk-invoiceExcessWtihGst"
                      type="checkbox"
                      @change="excessWithGstUpdate()"
                      v-model="invoice.excessWithGst"
                      :disabled="selectedInvoice">
                    <div class="state p-primary">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
              <vue-numeric class="input has-text-right"
                v-model.number="invoice.excessAmount"
                :min:="0"
                :precision="2"
                :disabled="hasExcessInvoice || selectedInvoice"
                @input="excessValueChanged()"
                @blur="calculateExcess()" />
            </div>
          </div>
          <div class="column">
            <label class="label">Owner Contribution</label>
            <vue-numeric class="input has-text-right"
              v-model.number="invoice.ownerContribution"
              :min:="0"
              :precision="2"
              :disabled="hasExcessInvoice || selectedInvoice"
              @input="updateInvoice()" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Discount</label>
              <vue-numeric class="input has-text-right"
                v-model.number="invoice.quoteDiscount"
                :min:="0"
                :precision="2"
                :disabled="selectedInvoice"
                @input="updateInvoice()" />
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="is-flex is-justify-content-space-between">
                <label class="label">GST</label>
                <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                  <label class="pretty-checkbox-label pr-1 is-size-7"
                    for="chk-fixed-gst">Fixed</label>
                  <div class="pretty p-icon p-round p-smooth m-0">
                    <input id="chk-fixed-gst"
                      type="checkbox"
                      v-model="fixedGst"
                      :disabled="selectedInvoice">
                    <div class="state p-primary">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model.number="invoice.gst"
                  :minus="true"
                  :precision="2"
                  @input="updateGst()"
                  :disabled="isGstInvoiceOn || selectedInvoice" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">GST</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model.number="invoice.gst"
                  :minus="true"
                  :precision="2"
                  @input="updateGst()"
                  :disabled="isGstInvoiceOn || selectedInvoice" />
              </div>
            </div>
          </div>
          <div class="column" />
        </div>
      </div>
      <div class="field mt-2">
        <div class="control">
          <input type="checkbox"
            v-model="invoice.exported"
            class="is-checkradio"
            id="invoice-exported"
            :disabled="selectedInvoice">
          <label for="invoice-exported">Exported</label>
        </div>
      </div>
      <div class="field">
        <label class="label">Total Excl GST: <span class="has-text-success">{{ invoice.totalExGst | formatCurrency($userInfo.locale) }}</span></label>
      </div>
      <div class="field">
        <label class="label">Total Payable Incl GST: <span class="has-text-success">{{ invoice.totalIncGst | formatCurrency($userInfo.locale) }}</span></label>
      </div>
      <div class="is-divider mt-0 mb-4" />
      <div v-if="invoice && !isLoadingInvoice"
        class="field">
        <div class="is-narrow">
          <input type="checkbox"
            v-model="isGstInvoiceOn"
            class="is-checkradio"
            :id="`gstInvoice`"
            :disabled="selectedInvoice"
            @click="addRemoveGstInvoice($event.target.checked)">
          <label :for="`gstInvoice`">GST Invoice</label>
        </div>
        <div v-if="gstInvoice"
          class="mt-2">
          <div v-if="selectedInvoice">
            <label class="label">Payer</label>
            <span>{{ gstInvoice.assetName }}</span>
            <div class="field">
              <div class="control">
                <input type="checkbox"
                  v-model="gstInvoice.exported"
                  class="is-checkradio"
                  id="gstInvoice-exported"
                  :disabled="selectedInvoice || !$userInfo.isSupportUser">
                <label for="gstInvoice-exported">Exported</label>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="field">
              <payer-selector v-model="innerValue"
                @payer-select="onGstInvPayerSelect" />
            </div>
            <div class="field mt-2">
              <div class="control">
                <input type="checkbox"
                  v-model="gstInvoice.exported"
                  class="is-checkradio"
                  id="gst-invoice-exported"
                  :disabled="!$userInfo.isSupportUser">
                <label for="gst-invoice-exported">Exported</label>
              </div>
            </div>
          </div>
          <div class="is-divider mt-3 mb-4" />
        </div>
      </div>
      <div v-if="invoice && !isLoadingInvoice"
        class="field">
        <div class="control">
          <input type="checkbox"
            :disabled="selectedInvoice"
            v-model="isOverrideEnabled"
            class="is-checkradio"
            id="override">
          <label for="override">Enable Override</label>
        </div>
      </div>
      <div v-if="isOverrideEnabled">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Total Labour</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="invoice.labourOverride"
                  @input="onLabourOverrideChange()"
                  :min:="0"
                  :disabled="selectedInvoice"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">R &amp; R</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="invoice.rrOverride"
                  @input="onOverrideChange()"
                  :min:="0"
                  :disabled="selectedInvoice"
                  :precision="2" />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Repair</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="invoice.repairOverride"
                  @input="onOverrideChange()"
                  :min:="0"
                  :disabled="selectedInvoice"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Paint</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="invoice.paintOverride"
                  @input="onOverrideChange()"
                  :min:="0"
                  :disabled="selectedInvoice"
                  :precision="2" />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Mech</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="invoice.mechOverride"
                  @input="onOverrideChange()"
                  :min:="0"
                  :disabled="selectedInvoice"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <invoice-no-search-modal v-if="isInvoiceNoSearchModalActive"
      :active.sync="isInvoiceNoSearchModalActive"
      :invoice-type="1"
      @ok="changeInvoiceNo"
      @close="isInvoiceNoSearchModalActive = false" />
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import InvoiceNoSearchModal from './InvoiceNoSearchModal'
import VueNumeric from '@/components/VueNumeric'
import { InvoiceTypes, AssetTypes, InvoicePayerTypes, ItemCategoryTypes, ItemCategoryCodeTypes } from '@/enums'
import { QuoteInvoiceService } from '../services'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService.js'
import { QuoteInvoiceValidationMixin, QuoteAssessmentMixin, QuoteInvoiceStoreMixin } from '../mixins'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { GstModel } from '@/classes'
import PayerSelector from './PayerSelector'

export default {
  name: 'InvoiceControl',
  components: {
    InvoiceNoSearchModal,
    VueNumeric,
    PayerSelector
  },
  filters: {},
  mixins: [QuoteInvoiceValidationMixin, QuoteInvoiceStoreMixin, NumberFiltersMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    assessments: {
      type: Array,
      default: () => []
    },
    selectedInvoice: null
  },
  data() {
    return {
      innerValue: null,
      invoice: null,
      gstInvoice: null,
      itemsTotal: 0,
      isInvoiceNoSearchModalActive: false,
      insurerAsset: null,
      customerAsset: null,
      isLoadingInvoice: false,
      isGstInvoiceOn: false,
      isOverrideEnabled: false,
      excessChanged: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    invoicePayerTypes() {
      return InvoicePayerTypes
    },
    isMainInvoice() {
      if (this.selectedInvoice) {
        return this.invoice.includedAssessments.some((assessmentId) => {
          const assessment = this.value.quoteAssessments.find((a) => a.assessmentID === assessmentId)
          if (assessment?.subQuoteNo === 0) {
            return true
          }
          return false
        })
      }
      return this.assessments.some((a) => a.subQuoteNo === 0)
    },
    invoiceNoReference() {
      return this.invoice ? `${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}/${this.invoice.invoiceType}` : ''
    },
    hasExcessInvoice() {
      const index = this.innerValue.invoices.findIndex((i) => i.invoiceType === InvoiceTypes.Excess && !i.deleted)
      return index >= 0
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (this.invoice && !this.invoice?.isNew) {
        invoiceDate = new Date(`${this.invoice?.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    },
    invoiceLabourTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) =>
          ItemCategoryCodeTypes.itemTypes.filter((c) => c.valueCalculationType === 'Labour').some((c) => c.code === i.itemType)
        )
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceRrTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.RR === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceRepairTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.REP === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceRwaTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.RWA === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoicePaintTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.PAINT === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceFgTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.FIBER === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceCrushTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.CRUSH === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceCdTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.CD === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceMechTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.MECH === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceOpgTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.OPG === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoicePartTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.PART === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceMiscTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.MISC === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoiceSubletTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.SUBL === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoicePdrrrTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.PDRRR === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    invoicePdrreTotal() {
      let total = 0
      this.assessments.forEach((assessment) => {
        const items = assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => ItemCategoryTypes.PDRRE === i.itemType)
        total += this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      })
      return total
    },
    overrideChanges() {
      if (this.invoice) {
        return `${this.invoice.labourOverride}|${this.invoice.paintOverride}|${this.invoice.mechOverride}|${this.invoice.repairOverride}|${this.invoice.rrOverride}`
      }
      return ''
    }
  },
  watch: {
    value: {
      handler(val) {
        this.innerValue = _cloneDeep(val)
      },
      deep: true
    },
    async assessments() {
      this.calculateItemsTotal()
      this.isLoadingInvoice = false
    },
    itemsTotal: {
      handler() {
        this.updateInvoice()
      },
      deep: true
    },
    overrideChanges() {
      this.calculateItemsTotal()
    },
    isOverrideEnabled() {
      this.clearOverrides()
    },
    selectedInvoice(newVal) {
      if (newVal) {
        this.invoice = _cloneDeep(newVal)
      }
    }
  },
  async created() {
    this.innerValue = _cloneDeep(this.value)
    this.isLoadingInvoice = true
    await this.createInvoice()
    await this.getAssets()
    this.calculateItemsTotal()
    this.isLoadingInvoice = false
  },
  mounted() {},
  methods: {
    async createInvoice() {
      this.invoice = await QuoteInvoiceService.getNewEntity()
      this.invoice.InvoiceType = InvoiceTypes.Quote
      this.invoice.invoicePayingType = InvoicePayerTypes.Insurer
      this.invoice.gstRate = this.innerValue.gstRate
      this.invoice.assetType = AssetTypes.Insurer
      this.invoice.assetId = this.innerValue.insurerId
      this.invoice.ownerContribution = this.innerValue.ownersContribution
      this.invoice.quoteDiscount = this.innerValue.discount
      this.invoice.excessAmount = this.innerValue.excess
      this.invoice.includedAssessments = this.assessments.map((a) => a.assessmentID)
      this.invoice.excessWithGst = this.innerValue.excessWithGst
      this.updateInvoice()
    },
    async addRemoveGstInvoice(checked) {
      if (checked) {
        // Create new GST invoice
        this.gstInvoice = await QuoteInvoiceService.getNewEntity()
        this.gstInvoice.invoiceNo = this.invoice.invoiceNo
        this.gstInvoice.gst = this.invoice.gst

        const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
        this.gstInvoice.invoiceDate = utcDate.split('.')[0] + 'Z'
        this.gstInvoice.totalExGst = 0
        this.gstInvoice.totalIncGst = this.gstInvoice.gst
        this.gstInvoice.invoiceType = InvoiceTypes.Gst
        this.gstInvoice.assetId = ''
        this.gstInvoice.assetType = ''
        this.gstInvoice.invoicePayingType = 1
        this.gstInvoice.exported = false
        this.gstInvoice.includedQuotes.splice(this.gstInvoice.includedQuotes.length, 1, this.innerValue.quoteId)
        this.gstInvoice.includedAssessments = this.assessments.map((a) => a.assessmentID)
        this.fixedGst = true
        this.invoice.gst = 0
        this.updateInvoice()
      } else {
        // Delete/Remove existing GST invoice
        this.fixedGst = false
        this.updateInvoice()
        this.gstInvoice = null
      }
    },
    async getAssets() {
      const insurerAssetFilter = {
        assetId: this.innerValue.insurerId,
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 1
      }
      const customerAssetFilter = {
        assetId: this.innerValue.customerId,
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 1
      }
      let insurers
      let customers
      ;[insurers, customers] = await Promise.all([
        QuickInvoiceService.getAssetDropdownProto(insurerAssetFilter),
        QuickInvoiceService.getAssetDropdownProto(customerAssetFilter)
      ])
      this.insurerAsset = insurers[0]
      this.customerAsset = customers[0]
    },
    onThirdPartyPayerSelect(asset) {
      this.invoice.assetId = asset.assetId
      this.invoice.assetType = asset.assetType
      this.invoice.invoiceGlCode = asset.assetGlCode
      this.invoice.assetName = asset.assetName
    },
    onGstInvPayerSelect(asset) {
      this.gstInvoice.assetId = asset.assetId
      this.gstInvoice.assetType = asset.assetType
      this.gstInvoice.invoiceGlCode = asset.assetGlCode
      this.gstInvoice.assetName = asset.assetName
    },
    showChangeInvoiceNoModal() {
      this.isInvoiceNoSearchModalActive = true
    },
    changeInvoiceNo(invoiceNo) {
      this.isInvoiceNoSearchModalActive = false
      this.invoice.invoiceNo = invoiceNo
      this.invoice.invoiceNoRef = invoiceNoReference
    },
    onInvoicePayerTypeClick(payerType) {
      if (payerType === InvoicePayerTypes.Insurer && this.insurerAsset) {
        this.invoice.assetId = this.insurerAsset.assetId
        this.invoice.assetName = this.insurerAsset.assetName
        this.invoice.assetType = this.insurerAsset.assetType
        this.invoice.invoiceGlCode = this.insurerAsset.assetGlCode
      } else if (payerType === InvoicePayerTypes.Private && this.customerAsset) {
        this.invoice.assetId = this.customerAsset.assetId
        this.invoice.assetName = this.customerAsset.assetName
        this.invoice.assetType = this.customerAsset.assetType
        this.invoice.invoiceGlCode = this.customerAsset.assetGlCode
      } else if (payerType === InvoicePayerTypes.ThirdParty) {
        this.invoice.assetId = null
        this.invoice.assetName = null
        this.invoice.assetType = null
        this.invoice.invoiceGlCode = null
      }
    },
    calculateItemsTotal() {
      if (this.invoice) {
        if (!this.isOverrideEnabled)
          this.itemsTotal =
            this.invoiceRrTotal +
            this.invoiceRepairTotal +
            this.invoiceRwaTotal +
            this.invoicePaintTotal +
            this.invoiceFgTotal +
            this.invoiceCrushTotal +
            this.invoiceCdTotal +
            this.invoiceMechTotal +
            this.invoiceOpgTotal +
            this.invoicePartTotal +
            this.invoiceMiscTotal +
            this.invoiceSubletTotal
        else {
          if (this.invoice.labourOverride !== 0) {
            this.itemsTotal = this.invoice.labourOverride + this.invoicePartTotal + this.invoiceMiscTotal + this.invoiceSubletTotal
          } else {
            this.itemsTotal =
              (this.invoice.rrOverride !== 0 ? this.invoice.rrOverride : this.invoiceRrTotal) +
              (this.invoice.repairOverride !== 0 ? this.invoice.repairOverride : this.invoiceRepairTotal) +
              (this.invoice.paintOverride !== 0 ? this.invoice.paintOverride : this.invoicePaintTotal) +
              this.invoiceRwaTotal +
              this.invoiceFgTotal +
              this.invoiceCrushTotal +
              this.invoiceCdTotal +
              (this.invoice.mechOverride !== 0 ? this.invoice.mechOverride : this.invoiceMechTotal) +
              this.invoiceOpgTotal +
              this.invoicePartTotal +
              this.invoiceMiscTotal +
              this.invoiceSubletTotal
          }
        }
      }
    },
    calculateDeductionAmount() {
      let deductionAmount = 0
      if (this.invoice && this.isMainInvoice) {
        let ownerContributionGst = 0
        if (!this.invoice.excessWithGst) {
          ownerContributionGst = roundAwayFromZero((this.invoice.ownerContribution * this.invoice.gstRate) / 100)
        }
        deductionAmount = this.invoice.quoteDiscount + this.invoice.excessAmount + this.invoice.ownerContribution + ownerContributionGst
      }
      return deductionAmount
    },
    updateInvoice(reset = true) {
      if (this.invoice && !this.selectedInvoice) {
        if (this.isMainInvoice) {
          const deductionAmount = this.calculateDeductionAmount()
          this.invoice.totalExGst = roundAwayFromZero(this.itemsTotal - deductionAmount)

          if (!this.invoice.excessWithGst) {
            if (reset && !this.isFixedGst) {
              this.invoice.gst = roundAwayFromZero(((this.itemsTotal - this.invoice.quoteDiscount) * this.innerValue.gstRate) / 100)
            }
          } else {
            if (reset && !this.isFixedGst) {
              this.invoice.gst = roundAwayFromZero((this.invoice.totalExGst * this.invoice.gstRate) / 100)
            }
          }
          this.invoice.totalIncGst = roundAwayFromZero(this.invoice.totalExGst + this.invoice.gst)
          this.UpdateInvAmount()
        } else {
          if (reset && !this.isFixedGst) {
            this.invoice.gst = roundAwayFromZero((this.itemsTotal * this.invoice.gstRate) / 100)
          }
          this.invoice.totalExGst = roundAwayFromZero(this.itemsTotal)
          this.invoice.totalIncGst = roundAwayFromZero(this.invoice.totalExGst + this.invoice.gst)
        }
        this.invoice.includedAssessments = this.assessments.map((a) => a.assessmentID)
        this.invoice.labourTotal = this.invoiceLabourTotal
        this.invoice.partTotal = this.invoicePartTotal
        this.invoice.miscTotal = this.invoiceMiscTotal
        this.invoice.rrTotal = this.invoiceRrTotal
        this.invoice.repairTotal = this.invoiceRepairTotal
        this.invoice.subletTotal = this.invoiceSubletTotal
        this.invoice.paintTotal = this.invoicePaintTotal
        this.invoice.mechTotal = this.invoiceMechTotal
        this.invoice.opgTotal = this.invoiceOpgTotal
        this.invoice.fgTotal = this.invoiceFgTotal
        this.invoice.crushTotal = this.invoiceCrushTotal
        this.invoice.cdTotal = this.invoiceCdTotal
        this.invoice.rwaTotal = this.invoiceRwaTotal
        this.invoice.pdrreTotal = this.invoicePdrreTotal
        this.invoice.pdrrrTotal = this.invoicePdrrrTotal
      }
    },
    updateGst() {
      this.invoice.totalIncGst = roundAwayFromZero(this.invoice.totalExGst + this.invoice.gst)
    },
    updateQuote() {
      this.innerValue.excess = this.invoice.excessAmount
      this.innerValue.ownersContribution = this.invoice.ownerContribution
      this.innerValue.discount = this.invoice.quoteDiscount
      this.$emit('input', this.innerValue)
    },
    UpdateInvAmount() {
      if (this.isMainInvoice) {
        const deductionAmount = this.calculateDeductionAmount()
        this.$emit('update-deductions', deductionAmount)
      }
    },
    raiseInvoice() {
      this.updateQuote()
      let invoices = [this.invoice]
      if (this.gstInvoice) {
        invoices.push(this.gstInvoice)
      }
      this.$emit('raise-invoice', invoices)
      this.invoice = null
    },
    toggleOverride(checked) {
      this.isOverrideEnabled = checked
    },
    calculateExcess() {
      if (this.invoice.excessWithGst && this.excessChanged) {
        const gstModel = new GstModel(this.innerValue.gstRate)
        this.invoice.excessAmount = roundAwayFromZero(this.invoice.excessAmount / gstModel.multiplier)
      }
      this.updateInvoice()
      this.excessChanged = false
    },
    excessWithGstUpdate() {
      this.fixedGst = false
      if (this.invoice.excessWithGst) {
        this.excessChanged = true
        this.calculateExcess()
      }
      this.updateInvoice()
    },
    excessValueChanged() {
      this.excessChanged = true
      this.updateInvoice()
    },
    onLabourOverrideChange() {
      this.invoice.rrOverride = 0
      this.invoice.repairOverride = 0
      this.invoice.paintOverride = 0
      this.invoice.mechOverride = 0
    },
    onOverrideChange() {
      this.invoice.labourOverride = 0
    },
    clearOverrides() {
      this.invoice.labourOverride = 0
      this.invoice.rrOverride = 0
      this.invoice.repairOverride = 0
      this.invoice.paintOverride = 0
      this.invoice.mechOverride = 0
    }
  }
}
</script>

<style lang="scss" scoped>
</style>