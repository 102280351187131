<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="minWidth"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium">
              <i class="mdi mdi-36px mdi-view-list" />
            </span>
            <span class="ml-2">Category Totals</span>
          </p>
        </header>
        <section class="modal-card-body">
          <article>
            <quote-detail-assessment-summary :value="value"
              :is-side="false" />
          </article>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button tooltip is-tooltip-topright"
            @click="cancel()"
            data-tooltip="Cancel editing quote labour rates">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import QuoteDetailAssessmentSummary from '../QuoteDetailAssessmentSummary.vue'

export default {
  name: 'EditRatesModal',
  directives: {
  },
  components: {
    BaseModalEx,
    QuoteDetailAssessmentSummary
  },
  mixins: [],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      isActive: false
    }
  },
  computed: {
    minWidth() {
      return this.value.quoteAssessments.lenght > 0 ? this.value.quoteAssessments.lenght * 50 : 30
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    this.isActive = this.active || false
  },
  beforeDestroy() {},
  methods: {
    cancel() {
      this.isActive = false
      this.$emit('close')
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.is-flex-items-end {
  display: flex;
  align-items: flex-end;
}
.modal-card-body {
  min-height: 15em;
}
.button.is-static {
  min-width: 4.5rem;
}
</style>
